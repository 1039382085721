<template>
  <section id="global-variables-list">
    <div v-if="getIsMobile">
      Menu Management can't available on smaller screens
    </div>
    <div v-else>
      <el-row v-loading="loading" type="flex" justify="center" :gutter="40">
        <el-col :span="14">
          <h2 class="h2 f-weight-400 mt-0 d-flex align-center menu-icon">
            <el-button
              size="mini"
              icon="el-icon-back"
              class="default-button btn button-left"
              @click="goBack()"
              >Back</el-button
            >
            <icons :iconName="'dashboard'"></icons>
            <span class="text pl-1">Menu Management</span>
          </h2>

          <div>
            <el-row>
              <el-col :span="8">
                <div class="mt-2">
                  <el-select
                    v-model="user_type"
                    filterable
                    default-first-option
                    @change="changeUserTypeAlert"
                    placeholder="Select Role"
                  >
                    <el-option
                      v-for="item in getUserTypeList"
                      :key="item._id"
                      :label="getLabel(item)"
                      :value="item._id"
                    ></el-option>
                  </el-select>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="user-menu-container mt-15">
            <el-select
              v-if="
                !selectedMenuId &&
                user_type &&
                allMenuItems &&
                allMenuItems.length
              "
              v-model="copyMenuItemId"
              class="menuPreviewButton"
              style="width: 150px; margin-right: 125px"
              placeholder="Select role"
              @change="copyMenu"
            >
              <el-option
                v-for="(menu, i) in allMenuItems"
                :key="menu.user_type + i"
                :value="menu.menu_id"
                :label="menu.name"
              ></el-option>
            </el-select>

            <el-button
              v-if="this.data.length"
              type="primary"
              class="menuPreviewButton"
              icon="el-icon-video-play"
              @click="centerDialogVisible = true"
              >Preview</el-button
            >

            <el-button
              v-if="this.data.length"
              type="success"
              class="menuSubmitButton"
              icon="el-icon-check"
              @click="saveMenuListAlert"
              >Save</el-button
            >
            <el-button
              v-if="this.data.length && this.selectedMenuId && !loading"
              type="danger"
              class="menuDeleteButton"
              icon="el-icon-delete"
              @click="deleteMenuAlert"
              ></el-button
            >

            <el-button
              v-if="!this.data.length && this.selectedMenuId && !loading"
              type="danger"
              class="menuSubmitButton"
              icon="el-icon-check"
              @click="deleteMenuAlert"
              >Delete Menu</el-button
            >

            <div :class="this.data.length ? 'sidemenu' : 'scrollable px-4 '">
              <el-tree
                :data="data"
                node-key="id"
                default-expand-all
                :expand-on-click-node="false"
                v-if="this.data.length && !refresh"
                :props="defaultProps"
                draggable
                :indent="40"
                :allow-drop="allowDrop"
              >
                <template slot-scope="{ node, data }">
                  <div>
                    <!-- <el-button type="danger" icon="el-icon-delete" size="mini" circle></el-button> -->
                    <el-card shadow="hover" class="each-item pt-0">
                      <h4 class="h4 f-weight-500 mt-0 d-flex align-center card-icon">
                        <icons v-if="data.icon" :iconName="data.icon"></icons>
                        <span class="text pl-1"
                          >{{ node.label }} {{ data.title|truncate(30,"...") }}</span
                        >
                      </h4>
                      <div class="actions-btn float-right">
                        <el-button
                          type="text"
                          size="medium"
                          @click="moveToTop(data, node)"
                          :disabled="checkIsFirstNode(data, node)"
                        >
                          <i class="el-icon-top yale-blue"></i>
                        </el-button>
                        <el-button
                          type="text"
                          size="medium"
                          @click="moveToBottom(data, node)"
                          :disabled="checkIsLastNode(data, node)"
                        >
                          <i class="el-icon-bottom yale-blue"></i>
                        </el-button>

                        <el-button
                          type="text"
                          size="medium"
                          @click="editMenuItem(data, node)"
                        >
                          <i class="el-icon-edit warning"></i>
                        </el-button>

                        <el-button
                          type="text"
                          size="medium"
                          @click="deleteMenuItem(data, node)"
                        >
                          <i class="el-icon-delete tomato"></i>
                        </el-button>
                      </div>
                    </el-card>
                  </div>
                </template>
              </el-tree>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <el-card shadow="never" class="px-20 mt-2">
            <el-form v-model="form">
              <el-form-item class="mb-10" label="Title">
                <br />
                <el-input
                  placeholder="Please Enter title"
                  v-model="form.title"
                ></el-input>
              </el-form-item>

              <el-form-item class="mb-10" label="Type">
                <br />
                <el-radio-group
                  v-model="form.type"
                  @change="fetchParents(form.title)"
                >
                  <el-row>
                    <el-col :span="18"
                      ><el-radio label="Individual"></el-radio
                    ></el-col>
                    <el-col :span="6"
                      ><el-radio label="Parent"></el-radio
                    ></el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="18"
                      ><el-radio
                        label="SubParent"
                        v-if="this.data.length"
                      ></el-radio
                    ></el-col>
                    <el-col :span="6"
                      ><el-radio
                        label="Child"
                        v-if="this.data.length"
                      ></el-radio
                    ></el-col>
                  </el-row>
                </el-radio-group>
              </el-form-item>

              <el-form-item
                class="mb-15"
                label="Select Parent"
                v-if="form.type == 'Child' || form.type == 'SubParent'"
              >
              <br/>
                <el-select v-model="form.parent" placeholder="Select Parent">
                  <el-option
                    v-for="(item, index) in parents"
                    :key="index"
                    :label="item.title"
                    :value="item.title"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                class="mb-10"
                label="Feature"
                v-if="
                  form.type && form.type != 'Parent' && form.type != 'SubParent'
                "
              >
                <br />
                <el-select
                  v-model="form.feature"
                  placeholder="Select Feature"
                  @change="fetchCategories(form.feature)"
                >
                  <el-option
                    v-for="item in features"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item 
              class="mb-10"
                label="Link"
                v-if="form.feature == 'EXTERNAL_LINK'">
                <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="Please enter Link URL"
                  @blur="validateUrl"
                  v-model="form.linkData"
                ></el-input>
                <span v-if="isUrlValid" style="color: red;">Please enter a valid URL.</span>
              </el-form-item>
              <el-form-item
                class="mb-10"
                label
                v-if="
                  form.type &&
                  form.type != 'Parent' &&
                  form.type != 'SubParent' &&
                  form.feature &&
                  form.feature != 'ENTITY_VIEWS' &&
                  form.feature != 'PAGE_DASHBOARD' &&
                  form.feature != 'A_FORM_TEMPLATE' &&
                  form.feature != 'EXTERNAL_LINK' &&
                  getPermission(form.feature)
                "
              >
                <el-radio-group v-model="form.isGroup">
                  <el-radio :label="form.feature">{{
                    form.feature.replace("_", " ")
                  }}</el-radio>
                  <el-radio label="Groups">Groups</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                class="mb-10"
                label="Entity views"
                v-if="form.feature == 'ENTITY_VIEWS'"
              >
              <br/>
                <el-select
                  v-model="form.entityviews_id"
                  placeholder="Select entity view "
                  filterable
                >
                  <el-option
                    v-for="view in allEntityViews"
                    :key="view._id"
                    :label="view.name"
                    :value="view._id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                class="mb-10"
                label="Select a form template"
                v-if="
                  form.feature == 'A_FORM_TEMPLATE' && 
                  form.type != 'Parent' &&
                  form.type != 'SubParent'
                "
              >
              <br/>
                <el-select
                  v-model="form.template_id"
                  placeholder="Select a form template"
                  filterable
                >
                  <el-option
                    v-for="template in allFormTemplates.filter(e => e.type != 'STANDARD')"
                    :key="template._id"
                    :label="template.name"
                    :value="template._id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                class="mb-10"
                label="Page Dashboard"
                v-if="form.feature == 'PAGE_DASHBOARD'"
              >
                <br/>
                <el-select v-model="form.page_dashboard" filterable clearable>
                  <el-option v-for="(dashboard, index) in getUserDashboardsList" :key="index" :value="dashboard._id" :label="dashboard.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                class="mb-10"
                label="Form builder"
                v-if="
                  form.feature == 'FORM_BUILDER' &&
                  form.isGroup == 'FORM_BUILDER'
                "
              >
              <br/>
                <el-select
                  v-model="form.formbuilder_id"
                  placeholder="Select Form builder"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="workflow in allFormbuilderData"
                    :key="workflow._id"
                    :label="workflow.name"
                    :value="workflow._id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                class="mb-10"
                label="Workflow"
                v-if="
                  form.feature == 'WORKFLOWS' && form.isGroup == 'WORKFLOWS'
                "
              >
                <br />
                <el-select
                  v-model="form.workflow_id"
                  placeholder="Select Workflow"
                  filterable
                >
                  <el-option
                    v-for="workflow in allWorkflowsData"
                    :key="workflow._id"
                    :label="workflow.name"
                    :value="workflow._id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                class="mb-10"
                label="Entity"
                v-if="
                  form.feature == 'ENTITIES' &&
                  form.isGroup == 'ENTITIES' &&
                  form.type != 'Parent' &&
                  form.type != 'SubParent'
                "
              >
                <br />
                <el-select
                  v-model="form.entity_id"
                  placeholder="Select Entity"
                  filterable
                  @change="getFeatures"
                >
                  <el-option
                    v-for="entity in allEntitesData"
                    :key="entity._id"
                    :label="entity.name"
                    :value="entity._id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                class="mb-10"
                label="Filter"
                v-if="
                  form.feature == 'ENTITIES' &&
                  form.isGroup == 'ENTITIES' &&
                  form.type != 'Parent' &&
                  form.type != 'SubParent' &&
                  form.entity_id
                "
              >
                <br />
                <el-select v-model="form.filters" clearable>
                  <el-option
                    v-for="filter in entityAllFilters"
                    :key="filter._id"
                    :label="filter.filter_name"
                    :value="filter._id"
                    >{{ filter.filter_name }}</el-option
                  >
                </el-select>
              </el-form-item>
              <el-form-item
                class="mb-10"
                label="Customization"
                v-if="
                  form.feature == 'ENTITIES' &&
                  form.isGroup == 'ENTITIES' &&
                  form.type != 'Parent' &&
                  form.type != 'SubParent' &&
                  form.entity_id
                "
              >
                <br />
                <el-select v-model="form.customization" clearable>
                  <el-option
                    v-for="customization in entityAllCustomizations"
                    :key="customization._id"
                    :label="customization.label"
                    :value="customization._id"
                    >{{ customization.label }}</el-option
                  >
                </el-select>
              </el-form-item>
              <el-form-item
                class="mb-10"
                label="Owner Navigations"
                v-if="
                  form.feature == 'OWNERROUTES' &&
                  form.isGroup == 'OWNERROUTES' &&
                  form.type != 'Parent' &&
                  form.type != 'SubParent'
                "
              >
              <br/>
                <el-select
                  v-model="form.route_id"
                  placeholder="Select Menu Item"
                  filterable
                  @change="setPermission($event)"
                >
                  <el-option
                    v-for="route in defaultRoutes"
                    :key="route._id"
                    :label="route.label"
                    :value="route._id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                class="mb-10"
                label="Groups List"
                v-if="
                  form.type &&
                  form.type != 'Parent' &&
                  form.type != 'SubParent' &&
                  form.isGroup == 'Groups'
                "
              >
              <br/>
                <el-select
                  v-model="form.groups"
                  placeholder="Select Group"
                  filterable
                >
                  <el-option
                    v-for="groupInfo in allEntitiesGroups"
                    :key="groupInfo._id"
                    :label="groupInfo.name"
                    :value="groupInfo._id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
              class="mb-10"
                v-if="form.isGroup == 'ENTITIES' && form.feature == 'ENTITIES'">
                <el-checkbox v-model="form.bulkUpdate">Bulk update</el-checkbox>
                <el-checkbox v-model="form.redirectToAdd">Redirect to ADD</el-checkbox>
              </el-form-item>
              <el-form-item class="mb-10"
                v-if="form.isGroup == 'ENTITIES' && form.feature == 'ENTITIES'" label="Redirect Menu Item">
                <el-tooltip
            content="After adding the data to the entity, the page will redirect to the selected menu item."
          >
            <i class="el-icon-info" style="margin-left: 5px"></i> </el-tooltip
          >
                <el-select v-model="form.redirectMenuItem"  placeholder="Select menu Item" filterable clearable>
                  <el-option v-for="(menu, index) in getAllMenuItems" :key="index" :value="menu._id" :label="menu.title">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Menu Icon">
                <icons class="menu-icon" v-if="form.icon" :iconName="selectedIcon"></icons>
                <el-button
                  type="text"
                  class="mb-5 text-center"
                  @click="iconPickerdialogVisible = true"
                >
                  {{ form.icon ? "Change Icon" : "Select Icon" }}
                </el-button>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="success"
                  :disabled="checkFormDisabled"
                  @click="onMenuItemSubmit"
                  >Add</el-button
                >

                <el-button @click="onMenuItemCancel" plain>Cancel</el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </el-col>
      </el-row>
      <dialog-component
        :title="'MENU PREVIEW'"
        :visible="centerDialogVisible"
        @before-close="centerDialogVisibleClose"
        :containerWidth="'40%'"
        :containerMaxHeight="'80%'"
        :isShowFooter="false"
        center
      >
        <el-aside width="250px">
          <el-menu>
            <!-- <template v-if="sidebaritem.children && sidebaritem.children.length"> -->
            <el-submenu
              v-for="(sidebaritem, index) in data"
              :key="index"
              :index="sidebaritem.title"
            >
              <template slot="title">
                <icons v-if="sidebaritem.icon" :iconName="sidebaritem.icon"></icons>&nbsp;&nbsp;&nbsp;
                <span style="margin-left: 1em">{{ sidebaritem.title }}</span>
              </template>
              <el-menu-item-group v-if="sidebaritem.children">
                <el-submenu
                  v-for="(sidebarsubitem, index) in sidebaritem.children"
                  :key="index"
                  :index="sidebarsubitem.title"
                >
                  <template slot="title">
                    <icons v-if="sidebarsubitem.icon" :iconName="sidebarsubitem.icon"></icons>&nbsp;&nbsp;&nbsp;
                    <span style="margin-left: 1em">{{
                      sidebarsubitem.title
                    }}</span>
                  </template>
                  <el-menu-item-group v-if="sidebarsubitem.children">
                    <el-menu-item
                      v-for="(
                        sidebarsubsubitem, index
                      ) in sidebarsubitem.children"
                      :key="index"
                    >
                      <icons v-if="sidebaritem.icon" :iconName="sidebaritem.icon"></icons>&nbsp;&nbsp;&nbsp;
                      <span style="margin-left: 1em">
                        {{ sidebarsubsubitem.title }}</span
                      >
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
              </el-menu-item-group>
            </el-submenu>
            <!-- </template> -->
            <!-- </template> -->
          </el-menu>
        </el-aside>
      </dialog-component>
      <dialog-component
        :title="'Select Menu Icon'"
        :visible="iconPickerdialogVisible"
        @before-close="closeIconPopUp"
        :containerWidth="'77%'"
        :containerMaxHeight="'80%'"
        :isShowFooter="false"
         class="icons-menu"
      >
      <icons-list @icon-data="setIconData"></icons-list>
        <!-- <div class="iconslistScrollable">
          <el-row :gutter="10">
            <el-col
              :xs="4"
              :sm="3"
              :md="2"
              :lg="2"
              :xl="1"
              v-for="(icon, index) in menuIcons"
              :key="index"
              class="border-radius"
            >
              <div
                class="grid-content ep-bg-purple"
                @click="setIconPath(icon)"
                style="cursor: pointer"
              >
                <el-tooltip
                  effect="dark"
                  :content="icon.name"
                  placement="top-start"
                >
                  <img
                    :src="require('@/assets/img/icons/menu/' + icon.url)"
                    :alt="icon.name"
                    width="25"
                  />
                </el-tooltip>
              </div>
            </el-col>
          </el-row>
        </div> -->
      </dialog-component>
      <dialog-component
        :title="'Warning'"
        :visible="dialogVisibleForMenuManagement"
        @before-close="dialogVisibleForMenuManagement=false"
        :containerHeight="'30%'"
      >
        <span>
          <h5 class="mt-20">It looks like you have been modifying something...</h5>
        </span>
        <span>If you leave before updating, your changes will be lost.</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleForMenuManagement = false" plain
            >Cancel</el-button
          >
          <el-button type="primary" @click="backToPrevious">Confirm</el-button>
        </span>
      </dialog-component>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
// import _ from "lodash";
import { isEqual } from "@/helpers/lodashHelper"
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
var menuIconsData = require("@/assets/data/menu_icons.json");
export default {
  computed: {
    checkFormDisabled() {
      return (
        !this.user_type ||
        !this.form.type ||
        !this.form.title ||
        (this.form.type != "Parent" &&
          !(this.form.type == "SubParent" && this.form.parent) &&
          ((!this.form.feature && this.form.isGroup != "Groups") ||
            (this.form.type == "Child" &&
              this.form.isGroup != "Groups" &&
              !this.form.parent) ||
            (this.form.feature == "ENTITIES" &&
              this.form.isGroup != "Groups" &&
              !this.form.entity_id) ||
            (this.form.feature == "WORKFLOWS" &&
              this.form.isGroup != "Groups" &&
              !this.form.workflow_id) ||
            (this.form.feature == "FORM_BUILDER" &&
              this.form.isGroup != "Groups" &&
              !this.form.formbuilder_id) ||
            (this.form.feature == "OWNERROUTES" &&
              this.form.isGroup != "Groups" &&
              !this.form.route_id) ||
            (this.form.isGroup == "Groups" && !this.form.groups)))
      );
    },
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    getAllMenuItems(){
      let menus = []
      this.data.map(menu => {
        if(!menu.children.length && (menu.feature !== 'ENTITIES' || menu.entity_id != this.form.entity_id)){
          menus.push(menu)
        }
        else{
          menu.children.map(sub => {
            if((!sub.children || !sub.children.length) && (sub.feature !== 'ENTITIES' || sub?.entity_id != this.form.entity_id)){
              menus.push(sub)
            }
            else{
              sub.children.map(sub1 => {
                if(sub1.feature !== 'ENTITIES' || sub1?.entity_id != this.form.entity_id){
                  menus.push(sub1)
                }
              })
            }
          })
        }
      })
      return menus
    },
    ...mapGetters("userTypes", ["getUserTypes"]),
    ...mapGetters("workflows", ["getAllWorkflowsData"]),
    ...mapGetters("menuManagementV2", [
      "getMenu",
      "getMenuManagementAddStatus",
      "getMenuManagementUpdateStatus",
      "getDeleteMenuStatus",
      "getMenuList",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getUpdateProfileStatus",
      "getUpdateProfileErrors",
      "getAddCompanyUser",
      "getCompanyUsers",
      "getGeneralErrorMessage",
      "getUserTypeList",
      "getActiveWorkspace",
      "getResendVerificationEmail",
      "getUserType",
    ]),
    ...mapGetters("entities", [
      "getAllEntities",
      "getEntityCreatedStatus",
      "getEntityCreatedData",
      "getEntityDeleteStatus",
      "getEntityDeleteError",
      "getEntityErrors",
    ]),
    ...mapGetters("templateWorkflow", ["getAllWorkflows"]),
    ...mapGetters("entityGroups", ["getAllEntityGroupsData"]),
    ...mapGetters("filters", ["getAllEntityFilters"]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("roles", ["getRoleById", "getSuccess", "getRoleErrors"]),
    ...mapGetters("formBuilders", ["getAllFormBuildersData"]),
    ...mapGetters("entityViews", ["getAllEntityViews"]),
    ...mapGetters("customDashboard", [
      "getUserDashboardsList",
    ]),
    ...mapGetters("entityCustomization",["getAllCustomizations"]),
    getPermission() {
      return (val) => {
        if (
          val === "DOC_TEMPLATE" ||
          val === "FORM_TEMPLATE" ||
          val === "OWNERROUTES"
        ) {
          return false;
        }
        return true;
      };
    },
    getLabel() {
      return (userType) => {
        if (userType && userType.role) {
          return userType.role.title + " - " + userType.name;
        } else {
          return userType.name;
        }
      };
    },
  },
  async mounted() {
    this.loading = true;
    await Promise.all([
      this.fetchEntityViews(),
      // this.fetchEntities(),
      this.fetchWorkflows(),
      this.fetchFormbuilder(),
      this.getCompanyUserTypeList(),
    ]);
    this.allEntitesData = await this.fetchAllEntities(false),
    this.allFormTemplates =  await this.fetchAllTemplates();
    this.loading = false;
  },
  mixins: [TemplateBuilderHelper],
  data() {
    return {
      selectedIcon: "",
      iconPickerdialogVisible: false,
      loading: false,
      openedSubmenu: [],
      menuIcons: menuIconsData,
      centerDialogVisible: false,
      currentVal: [],
      prevVal: [],
      dialogVisibleForMenuManagement: false,
      form: {
        title: "",
        feature: "",
        category: "",
        type: "",
        parent: "",
        icon: "dashboard.svg",
        children: [],
        permission: "",
        isGroup: "",
        groups: "",
        bulkUpdate: false,
        redirectToAdd : false,
        redirectMenuItem : '',
        customization : '',
        linkData: '',
      },
      values: [],
      parents: [],
      category: "",
      categories: [],
      feature: "",
      features: [
        {
          value: 'PAGE_DASHBOARD',
          label : 'Page Dashboard'
        },
        {
          value: "FORM_BUILDER",
          label: "Form builder",
        },
        {
          value: "WORKFLOWS",
          label: "Work Flows",
        },

        {
          value: "ENTITIES",
          label: "Entities",
        },
        {
          value: "ENTITY_VIEWS",
          label: "Entity views",
        },
        {
          value: "DOC_TEMPLATE",
          label: "Document Templates",
        },
        {
          value: "FORM_TEMPLATE",
          label: "Form Templates",
        },
        {
          value: "OWNERROUTES",
          label: "Owner Routes",
        },
        {
          value: "A_FORM_TEMPLATE",
          label: "A Form template",
        },
        {
          value: "EXTERNAL_LINK",
          label:"External Link"
        }
      ],
      data: [],
      previousMenuData: [],
      user_type: "",
      selectedUserType: "",
      editIndex: -1,
      childEditIndex: -1,
      subChildIndex: -1,
      id: 0,
      workflows: [
        {
          value: "WORKFLOW_DATA",
          label: "Workflow Data",
        },
      ],
      company_documents: [
        {
          value: "/company-documents/static",
          label: "Static Docs",
        },
        {
          value: "/company-documents/custom",
          label: "Custom Docs",
        },
        {
          value: "/company-documents/requested",
          label: "Requested",
        },
        {
          value: "/company-documents/standard",
          label: "Standard Docs",
        },
        {
          value: "/company-documents/offer-letters",
          label: "Offer Letters",
        },
      ],
      users: [
        {
          value: "ADMIN",
          label: "Admin",
        },
        {
          value: "HR",
          label: "HR",
        },
        {
          value: "CUSTOMER",
          label: "Customer",
        },
        {
          value: "VENDOR",
          label: "Vendor",
        },
        {
          value: "LEAD REPRESENTATIVE",
          label: "Lead Representative",
        },
      ],
      entities: [
        {
          value: "ENTITIES_DATA",
          label: "Entities Details",
        },
      ],
      staticRoutes: [
        {
          value: "OWNERROUTES",
          label: "Owner Routes",
        },
      ],
      defaultProps: {
        children: "children",
        title: "title",
      },
      allWorkflowsData: [],
      allEntitesData: [],
      allFormbuilderData: [],
      allEntityViews: [],
      existedMenuListId: "",
      refresh: false,
      selectedMenuId: "",
      defaultRoutes: [
        {
          value: "dashboard",
          label: "Dashboard",
          _id: "dashboard",
          permission: "dashboard",
        },
        {
          value: "documents/upload",
          label: "Create Document",
          _id: "documents/upload",
          permission: "createNewDocument",
        },
        {
          value: "documents/all",
          label: "Documents",
          _id: "documents/all",
          permission: "documents",
        },
        {
          value: "templates",
          label: "Document Templates",
          _id: "templates",
          permission: "documentTemplate",
        },
        {
          value: "contacts",
          label: "Contacts",
          _id: "contacts",
          permission: "contacts",
        },
        {
          value: "contact-types",
          label: "Contact Types",
          _id: "contact-types",
          permission: "createContactType",
        },
        {
          value: "workflows",
          label: "Workflows",
          _id: "workflows",
          permission: "workflows",
        },
        {
          value: "company/users",
          label: "Company Users",
          _id: "company/users",
          permission: "companyUsers",
        },
        // {
        //   value: "profilesettings",
        //   label: "Settings",
        //   _id: "/profilesettings",
        //   permission: "",
        // },
        // {
        //   value: "profile/companyProfile",
        //   label: "Company Info",
        //   _id: "profile/companyProfile",
        //   permission: "",
        // },
        {
          value: "profile/manage-permissions",
          label: "Permissions",
          _id: "profile/manage-permissions",
          permission: "companyUsers",
        },
        {
          value: "approval-forms",
          label: "Approval Forms",
          _id: "forms/approval-forms",
          permission: "viewForms",
        },
      ],
      allEntitiesGroups: [],
      entityAllFilters: [],
      entityAllCustomizations : [],
      allMenuItems: [],
      copyMenuItemId: "",
      subparent: [],
      currentEditNode: "",
      allFormTemplates: [],
     isUrlValid: false
     
    };
  },
  // filters: {
  //   getIcon(data) {
  //     return data.icon
  //       ? this.logoUploadUrl + "/assets/img/icons/menu/" + data.icon
  //       : this.logoUploadUrl + "/assets/img/icons/menu/dashboard.svg";
  //   },
  // },
  methods: {
    validateUrl() {
      if (this.form.linkData.trim() === '') {
        this.isUrlValid = false;
        return;
      }
      const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + 
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' + 
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + 
        '(\\?[;&a-z\\d%_.~+=-]*)?' + 
        '(\\#[-a-z\\d_]*)?$', 'i'
      );
      this.isUrlValid = !urlPattern.test(this.form.linkData);
    },
    async fetchEntityViews() {
      try {
        this.loading = true;
        let params = {
          get_all: true,
        };
        await this.$store.dispatch("entityViews/getAllEntityViews", params);
        if (this.getAllEntityViews) {
          this.allEntityViews =
            this.getAllEntityViews && this.getAllEntityViews.data
              ? this.getAllEntityViews.data
              : [];
          this.loading = false;
        } else {
          this.loading = false;
        }
      } catch (err) {
        console.log("fetchEntityViews",err);
        this.loading = false;
      }
    },
    centerDialogVisibleClose(){
      this.centerDialogVisible=false
    },
    async fetchFormbuilder() {
      try {
        this.loading = true;
        let params = {
          get_all: true,
        };
        await this.$store.dispatch("formBuilders/fetchAllFormBuilders", params);
        if (this.getAllFormBuildersData) {
          this.allFormbuilderData =
            this.getAllFormBuildersData && this.getAllFormBuildersData.data
              ? this.getAllFormBuildersData.data
              : [];
          this.loading = false;
        } else {
          this.loading = false;
        }
      } catch (err) {
        console.log("fetchFormbuilder",err);
        this.loading = false;
      }
    },
    allowDrop(draggingNode, dropNode, type) {
      if (
        !draggingNode?.childNodes.length &&
        draggingNode?.data?.type == "Parent" &&
        !draggingNode?.data?.feature
      ) {
        return false;
      } else if (
        !draggingNode?.childNodes.length &&
        draggingNode?.data?.type == "SubParent" &&
        !draggingNode?.data?.feature
      ) {
        return false;
      } else if (draggingNode?.childNodes.length) {
        if (
          draggingNode?.data?.children &&
          type != "inner" &&
          dropNode?.level < 3
        ) {
          return true;
        }
      } else if (dropNode?.level < 3) {
          if(dropNode.data.type ==  draggingNode.data.type && type =="inner")
          {
            return false;
          }
          else{
        return true;
          }
      } else if (
        dropNode?.level < 3 &&
        type != "inner" &&
        (!draggingNode?.data?.children || !draggingNode.data.children.length)
      ) {
        return true;
      } else if (
        dropNode?.level == 3 &&
        type != "inner" &&
        (!draggingNode?.data?.children ||
          !draggingNode?.data?.children.children ||
          !draggingNode.data.children.children.length)
      ) {
        return true;
      }
      return false;
    },
    copyMenu(id) {
      if (id) {
        let selectedMenu = this.allMenuItems.find((e) => e.menu_id == id);
        if (selectedMenu && selectedMenu.menu) {
          this.data = [...selectedMenu.menu];
        }
      }
    },
    moveToTop(data, node = {}) {
      if (data) {
        this.refresh = true;
        if (data.type == "Individual" || data.type == "Parent") {
          let currentIndex = this.data.findIndex((x) => x.title == data.title);
          if (currentIndex > 0) {
            let temp = this.data[currentIndex];
            this.data[currentIndex] = this.data[currentIndex - 1];
            this.data[currentIndex - 1] = temp;
          }
        } else if (data.type === "Child") {
          if (node.level == 2) {
            let currentIndex = this.data.findIndex(
              (x) => x.title == node.parent.data.title
            );
            let childEditIndex = this.data[currentIndex].children.findIndex(
              (y) => y.title == data.title
            );
            if (childEditIndex > 0) {
              let tempParent = this.data[currentIndex];
              let temp = tempParent.children[childEditIndex];
              tempParent.children[childEditIndex] =
                tempParent.children[childEditIndex - 1];
              tempParent.children[childEditIndex - 1] = temp;
              this.data[currentIndex] = tempParent;
            }
          } else if (node.level == 3) {
            // this.data.forEach((parent, i) => {
            //   let currentIndex = parent.children.findIndex(
            //     (x) => x.title == node.parent.data.title
            //   );
            this.data.forEach((e) => {
            if (e && e.children && e.children.length) {
              e.children.forEach((f) => {
                if (f.title === node.parent.data.title) {
                  this.subParentIndex = e.children.indexOf(f);
                  this.parentIndex = this.data.indexOf(e);
                }
              });
            }
          });
          let currentIndex = this.data[this.parentIndex].children.findIndex(
            (x) => x.title == node.parent.data.title
          );
              // if (currentIndex > 0) {
                let subChildIndex = this.data[this.parentIndex].children[
                  currentIndex
                ].children.findIndex((y) => y.title == data.title);
                if (subChildIndex > 0) {
                  let tempParent = this.data[this.parentIndex].children[currentIndex];
                  let temp = tempParent.children[subChildIndex];
                  tempParent.children[subChildIndex] =
                    tempParent.children[subChildIndex - 1];
                  tempParent.children[subChildIndex - 1] = temp;
                  this.data[this.parentIndex].children[currentIndex] = tempParent;
                }
              // }
            // });
          }
        } else if (data.type === "SubParent") {
          let currentIndex = this.data.findIndex(
            (x) => x.title == node.parent.data.title
          );
          let childEditIndex = this.data[currentIndex].children.findIndex(
            (y) => y.title == data.title
          );
          if (childEditIndex > 0) {
            let tempParent = this.data[currentIndex];
            let temp = tempParent.children[childEditIndex];
            tempParent.children[childEditIndex] =
              tempParent.children[childEditIndex - 1];
            tempParent.children[childEditIndex - 1] = temp;
            this.data[currentIndex] = tempParent;
          }
        }
        setTimeout(() => {
          this.refresh = false;
        });
      }
    },
    moveToBottom(data, node = {}) {
      if (data) {
        this.refresh = true;
        if (data.type == "Individual" || data.type == "Parent") {
          let currentIndex = this.data.findIndex((x) => x.title == data.title);
          if (currentIndex < this.data.length - 1) {
            let temp = this.data[currentIndex];
            this.data[currentIndex] = this.data[currentIndex + 1];
            this.data[currentIndex + 1] = temp;
          }
        } else if (data.type === "Child") {
          if (node.level == 2) {
            let currentIndex = this.data.findIndex(
              (x) => x.title == node.parent.data.title
            );
            let childEditIndex = this.data[currentIndex].children.findIndex(
              (y) => y.title == data.title
            );
            if (
              this.data[currentIndex].children &&
              childEditIndex < this.data[currentIndex].children.length - 1
            ) {
              let tempParent = this.data[currentIndex];
              let temp = tempParent.children[childEditIndex];
              tempParent.children[childEditIndex] =
                tempParent.children[childEditIndex + 1];
              tempParent.children[childEditIndex + 1] = temp;
              this.data[currentIndex] = tempParent;
            }
          } else if (node.level == 3) {
           
            // this.data.forEach((parent, i) => {
            //   let currentIndex = parent.children.findIndex(
            //     (x) => x.title == node.parent.data.title
            //   );
            this.data.forEach((e) => {
            if (e && e.children && e.children.length) {
              e.children.forEach((f) => {
                if (f.title === node.parent.data.title) {
                  this.subParentIndex = e.children.indexOf(f);
                  this.parentIndex = this.data.indexOf(e);
                }
              });
            }
          });
          let currentIndex = this.data[this.parentIndex].children.findIndex(
            (x) => x.title == node.parent.data.title
          );
              // if (currentIndex > 0) {
                let subChildIndex = this.data[this.parentIndex].children[
                  currentIndex
                ].children.findIndex((y) => y.title == data.title);
                if (
                  this.data[this.parentIndex].children[currentIndex].children &&
                  subChildIndex <
                    this.data[this.parentIndex].children[currentIndex].children.length - 1
                ) {
                  let tempParent = this.data[this.parentIndex].children[currentIndex];
                  let temp = tempParent.children[subChildIndex];
                  tempParent.children[subChildIndex] =
                    tempParent.children[subChildIndex + 1];
                  tempParent.children[subChildIndex + 1] = temp;
                  this.data[this.parentIndex].children[currentIndex] = tempParent;
                }
              // }
            // });
          }
        } else if (data.type === "SubParent") {
          let currentIndex = this.data.findIndex(
            (x) => x.title == node.parent.data.title
          );
          let childEditIndex = this.data[currentIndex].children.findIndex(
            (y) => y.title == data.title
          );
          if (
            this.data[currentIndex].children &&
            childEditIndex < this.data[currentIndex].children.length - 1
          ) {
            let tempParent = this.data[currentIndex];
            let temp = tempParent.children[childEditIndex];
            tempParent.children[childEditIndex] =
              tempParent.children[childEditIndex + 1];
            tempParent.children[childEditIndex + 1] = temp;
            this.data[currentIndex] = tempParent;
          }
        }
        setTimeout(() => {
          this.refresh = false;
        });
      }
    },
    checkIsFirstNode(data, node = {}) {
      if (data.type == "Individual" || data.type == "Parent") {
        let currentIndex = this.data.findIndex((x) => x.title == data.title);
        return currentIndex == 0;
      } else if (data.type === "Child") {
        if (node.level == 2) {
          let currentIndex = this.data.findIndex(
            (x) => x.title == node.parent.data.title
          );
          let childEditIndex = -1;
          if (currentIndex > -1) {
            childEditIndex = this.data[currentIndex].children.findIndex(
              (y) => y.title == data.title
            );
          }
          return childEditIndex == 0;
        } else if (node.level == 3) {
          this.data.forEach((e) => {
            if (e && e.children && e.children.length) {
              e.children.forEach((f) => {
                if (f.title === node.parent.data.title) {
                  this.subParentIndex = e.children.indexOf(f);
                  this.parentIndex = this.data.indexOf(e);
                }
              });
            }
          });
          let currentIndex = this.data[this.parentIndex].children.findIndex(
            (x) => x.title == node.parent.data.title
          );

          let subChildIndex = -1;
          if (currentIndex > -1) {
            subChildIndex = this.data[this.parentIndex].children[
              currentIndex
            ].children.findIndex((y) => y.title == data.title);
          }
          return subChildIndex == 0;
        }
      } else if (data.type === "SubParent") {
        let currentIndex = this.data.findIndex(
          (x) => x.title == node.parent.data.title
        );
        let childEditIndex = -1;
        if (currentIndex > -1) {
          childEditIndex = this.data[currentIndex].children.findIndex(
            (y) => y.title == data.title
          );
        }
        return childEditIndex == 0;
      }
      return false;
    },
    checkIsLastNode(data, node = {}) {
      if (data.type == "Individual" || data.type == "Parent") {
        let currentIndex = this.data.findIndex((x) => x.title == data.title);
        return currentIndex == this.data.length - 1;
      } else if (data.type === "Child") {
        if (node.level == 2) {
          let currentIndex = this.data.findIndex(
            (x) => x.title == node.parent.data.title
          );
          let childEditIndex = -1;
          if (currentIndex > -1) {
            childEditIndex = this.data[currentIndex].children.findIndex(
              (y) => y.title == data.title
            );
          }
          return (
            this.data[currentIndex] &&
            this.data[currentIndex].children &&
            childEditIndex == this.data[currentIndex].children.length - 1
          );
        } else if (node.level == 3) {
          this.data.forEach((e) => {
            if (e && e.children && e.children.length) {
              e.children.forEach((f) => {
                if (f.title === node.parent.data.title) {
                  this.subParentIndex = e.children.indexOf(f);
                  this.parentIndex = this.data.indexOf(e);
                }
              });
            }
          });
          let currentIndex = this.data[this.parentIndex].children.findIndex(
            (x) => x.title == node.parent.data.title
          );
          let subChildIndex = -1;
          if (currentIndex > -1) {
            subChildIndex = this.data[this.parentIndex].children[
              currentIndex
            ].children.findIndex((y) => y.title == data.title);
          }
          return (
            this.data[this.parentIndex].children[currentIndex] &&
            this.data[this.parentIndex].children[currentIndex].children &&
            subChildIndex ==
              this.data[this.parentIndex].children[currentIndex].children
                .length -
                1
          );
        }
      } else if (data.type === "SubParent") {
        let currentIndex = this.data.findIndex(
          (x) => x.title == node.parent.data.title
        );
        let childEditIndex = -1;
        if (currentIndex > -1) {
          childEditIndex = this.data[currentIndex].children.findIndex(
            (y) => y.title == data.title
          );
        }
        return (
          this.data[currentIndex] &&
          this.data[currentIndex].children &&
          childEditIndex == this.data[currentIndex].children.length - 1
        );
      }
      return false;
    },
    async getEntityFilters(entity_id) {
      this.loading = true;
      await this.$store.dispatch("filters/fetchAllEntityFilters", {
        entityId: entity_id,
      });
      this.entityAllFilters = [];
      if (this.form.filters) {
        this.form.filters = "";
      }
      if (this.getAllEntityFilters) {
        this.entityAllFilters = this.getAllEntityFilters;
      }
      this.loading = false;
    },
    async getFeatures(entity_id){
      await Promise.all([
        this.getEntityFilters(entity_id),
        this.getEntityCustomizations(entity_id)
      ])
    },
    async getEntityCustomizations(entity_id){
      this.loading = true;
      await this.$store.dispatch("entityCustomization/fetchAllEntityCustomizations",entity_id);
      this.entityAllCustomizations = []
      if(this.getAllCustomizations){
        this.entityAllCustomizations = this.getAllCustomizations.data
      }
      this.form.customization = ''
      this.loading = false
    },
    backToPrevious() {
      this.dialogVisibleForMenuManagement = false;
      this.$router.push({
        path: "/profile",
      });
    },
    checkForChanges() {
      if (
        this.data.length &&
        (!this.previousMenuData || !this.previousMenuData?.menu_list)
      ) {
        return true;
      } else if (
        this.previousMenuData &&
        this.data &&
        this.previousMenuData.menu_list &&
        this.previousMenuData.menu_list.length &&
        this.data.length &&
        this.data.length != this.previousMenuData.menu_list.length
      ) {
        return true;
      } else {
        let result = isEqual(
          this.data,
          this.previousMenuData && this.previousMenuData.menu_list
        );
        if (result === "true" || result === true || !this.userType) {
          return false;
        } else {
          return true;
        }
      }
    },
    goBack() {
      let checkChanges = this.checkForChanges();
      if (checkChanges) {
        this.dialogVisibleForMenuManagement = true;
      } else {
        this.dialogVisibleForMenuManagement = false;
        this.$router.push({
          path: "/profile",
        });
      }
    },
    setPermission(event) {
      // let result = _.filter(this.defaultRoutes, function (o) {
      //   return o._id === event;
      // });
      let result = this.defaultRoutes.filter((o) => o._id == event);
      if (result && result.length) {
        this.form.permission =
          result[0] && result[0].permission ? result[0].permission : "";
      }
    },
    async fetchAllRoles() {
      let ids = this.getUserTypeList.flatMap((e) => e._id);
      if (ids && ids.length) {
        await this.$store.dispatch("menuManagementV2/fetchMenuWithUserTypes", {
          user_types: ids,
        });

        if (this.getMenuList) {
          this.allMenuItems = [];
          this.getMenuList.forEach((menu) => {
            if (menu && menu.user_type) {
              let usertype = this.getUserTypeList.find(
                (e) => e._id == menu.user_type
              );
              if (usertype && usertype.name) {
                this.allMenuItems.push({
                  user_type: usertype._id,
                  menu: menu.menu_list,
                  name: usertype?.role?.title + "-" + usertype.name,
                  menu_id: menu._id,
                });
              }
            }
          });
          // this.allMenuItems = [...this.getMenuList];
        }
      }
    },
    async getCompanyUserTypeList() {
      try {
        await this.$store.dispatch(
          "auth/getUserTypeList",
          
         {companyId:this.getActiveWorkspace.company_id,isMenu:true}
        );
        if (this.getUserTypeList) {
          this.fetchAllRoles();
          // this.userType = this.getUserTypeList;
          // this.getUserTypeList.map((t) => {
          //   if (this.getUserType.name && this.getUserType.name == 'OWNER'){
          //     this.userType.push(t);
          //   }else if (t && t.name && this.getUserType.name && this.getUserType.name == 'ADMIN'){
          //     this.userType.push(t);
          //   }else if(t && t.name && this.getUserType.name && this.getUserType.name == 'MANAGER' && (t.name == 'MANAGER' || t.name == 'MEMBER')){
          //     this.userType.push(t);
          //   }else if(t && t.name && this.getUserType.name && this.getUserType.name == 'MEMBER' && t.name == 'MEMBER'){
          //     this.userType.push(t);
          //   }
          // });
        }
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    async fetchUserTypes() {
      await this.$store.dispatch("userTypes/fetchUserTypes", { get_all: true });
    },
    async fetchParents() {
      if (this.form.type === "Child") {
        this.parents = this.data.filter(
          (x, i) => x.type == "Parent" && i != this.editIndex
        );

        let subparents = [];
        await this.parents.forEach((parent) => {
          if (parent.children && parent.children.length) {
            parent.children.filter((child) => {
              if (child.type == "SubParent") {
                subparents.push(child);
              }
            });
          }
        });
        this.parents = [...this.parents, ...subparents];
      } else if (this.form.type === "SubParent") {
        this.parents = this.data.filter(
          (x, i) => x.type == "Parent" && i != this.editIndex
        );
      } else {
        this.parents = [];
      }
    },
    async fetchWorkflows() {
      try {
        this.loading = true;
        let params = {
          limit: 1000000,
          page: 1,
          status: "ACTIVE",
        };
        await this.$store.dispatch("templateWorkflow/fetchWorkflows", params);
        if (this.getAllWorkflows) {
          this.allWorkflowsData =
            this.getAllWorkflows && this.getAllWorkflows.data
              ? this.getAllWorkflows.data
              : [];
          this.loading = false;
        } else {
          this.loading = false;
        }
      } catch (err) {
        console.log("fetchWorkflows",err);
        this.loading = false;
      }
    },
    async fetAllEntityGroups(userType) {
      let params = {
        get_all: true,
      };
      if (this.getUserTypeList && this.getUserTypeList.length) {
        // let switchedRole = await this.lodash.filter(
        //   this.getUserTypeList,
        //   function (user) {
        //     return user._id === userType;
        //   }
        // );
        let switchedRole = this.getUserTypeList.filter(user => user._id === userType);
        if (switchedRole && switchedRole.length) {
          await this.$store.dispatch(
            "roles/fetchRoleById",
            switchedRole[0].role._id
          );
        }

        if (this.getRoleById && this.getRoleById) {
          params.selectedGroups = this.getRoleById.allowed_groups;
        }
      }

      this.loading = true;

      await this.$store.dispatch("entityGroups/fetEntityGroupsData", params);
      this.allEntitiesGroups = this.getAllEntityGroupsData
        ? this.getAllEntityGroupsData.data
        : [];
      this.loading = false;
    },
    async fetchEntities() {
      try {
        this.loading = true;
        let params = {
          get_all: true,
        };
        await this.$store.dispatch("entities/fetchEntities", params);

        this.allEntitesData = this.getAllEntities.data;
        this.loading = false;
      } catch (err) {
        console.log("fetchEntities",err);
      }
    },

    swapData(start, end) {
      let temp = this.data[start];
      this.data[start] = this.data[end];
      this.data[start] = temp;
    },

    async fetchCategories(val) {
      if (val === "WORKFLOWS") {
        this.categories = this.workflows;
      }
      if (val === "COMPANY_DOCUMENTS") {
        this.categories = this.company_documents;
      }
      if (val === "USERS") {
        this.categories = this.users;
      }
      if (val === "ENTITIES") {
        this.categories = this.entities;
      }
      if (val === "OWNERROUTES") {
        this.form.isGroup = "OWNERROUTES";
        this.categories = this.staticRoutes;
      }
      if (val === "DOC_TEMPLATE" || val === "FORM_TEMPLATE") {
        this.form.isGroup = "Groups";
      }

      // this.form.category = this.categories[0].value;

      if (this.allWorkflowsData.length == 0) {
        await this.fetchWorkflows();
      }

      if (this.allEntitesData.length == 0) {
        await this.fetchEntities(this.user_type);
      }
      if (this.allEntitiesGroups.length == 0) {
        this.fetAllEntityGroups();
      }
    },
    resetFormData() {
      this.form.title = "";
      this.form.feature = "";
      this.form.category = "";
      this.form.type = "";
      this.form.parent = "";
      this.form.icon = "dashboard.svg";
      this.form.children = [];
      this.form.permission = "";
      this.form.isGroup = "";
      this.form.groups = "";
      this.form.bulkUpdate = true;
    },
    changeUserTypeAlert(userType) {
      this.copyMenuItemId = "";
      let checkChanges = this.checkForChanges();
      this.fetchAllRoles();
      if (
        (this.selectedUserType &&
          !this.selectedMenuId &&
          this.data &&
          this.data.length) ||
        checkChanges
      ) {
        this.$confirm("Changes not saved. Discard the changes? ", "Warning", {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        })
          .then(async () => {
            this.onChangeUserType(userType);
          })
          .catch(() => {
            this.user_type = this.selectedUserType;
          });
      } else {
        this.onChangeUserType(userType);
      }
    },
    async onChangeUserType(userType) {
      try {
        this.resetFormData();
        this.fetAllEntityGroups(userType);
        this.loading = true;
        this.refresh = true;
        this.data = [];
        this.previousMenuData = [];
        this.selectedUserType = userType;
        await Promise.all(
          [
            this.$store.dispatch("menuManagementV2/fetchMenuWithUserType", userType),
            this.$store.dispatch("customDashboard/fetchUserDashboardsList", userType)
          ]
        );
        if (this.getMenu) {
          this.previousMenuData = this.getMenu;
          this.data = this.previousMenuData
            ? this.previousMenuData.menu_list.map((e) => {
                if (e.entity_id && e.entity_id._id) {
                  e.entity_id = e.entity_id._id;
                }

                if (e.workflow_id && e.workflow_id._id) {
                  e.workflow_id = e.workflow_id._id;
                }
                if (e.formbuilder_id && e.formbuilder_id._id) {
                  e.formbuilder_id = e.formbuilder_id._id;
                }
                if (e.filters && e.filters._id) {
                  e.filters = e.filters._id;
                }
                e.children = (e.children || []).map((el) => {
                  if (el.entity_id && el.entity_id._id) {
                    el.entity_id = el.entity_id._id;
                  }

                  if (el.workflow_id && el.workflow_id._id) {
                    el.workflow_id = el.workflow_id._id;
                  }
                  if (el.formbuilder_id && el.formbuilder_id._id) {
                    el.formbuilder_id = el.formbuilder_id._id;
                  }
                  if (el.filters && el.filters._id) {
                    el.filters = el.filters._id;
                  }
                  return el;
                });
                return e;
              })
            : [];
          this.selectedUserType = this.previousMenuData.user_type;
          this.selectedMenuId = this.previousMenuData._id;
        } else {
          this.data = [];

          this.selectedMenuId = "";
        }
        this.refresh = false;
        this.loading = false;
      } catch (err) {
        console.log("onChangeUserType",err);
        this.refresh = false;
        this.loading = false;
        this.selectedMenuId = this.previousMenuData._id;
        this.errorConfirm();
      }
    },
    deleteMenuAlert() {
      this.$confirm("Are you sure to delete the menu?", "Warning", {
        confirmButtonText: "Continue",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(async () => {
        this.deleteMenu();
      });
    },
    async deleteMenu() {
      await this.$store.dispatch(
        "menuManagementV2/deleteMenuById",
        this.selectedMenuId
      );
      if (this.getDeleteMenuStatus) {
        this.onChangeUserType(this.user_type);
        this.fetchAllRoles();
        this.resetFormData();
        this.$notify.success({
          title: "Success",
          message: "Menu Deleted. Default menu will be configured.",
        });
      } else {
        this.$notify.success({
          title: "error",
          message: "Error while deleting menu.",
        });
      }
    },
    saveMenuListAlert() {
      if (this.selectedMenuId) {
        this.saveMenuList();
      } else {
        this.$confirm("Are you sure to save the menu?", "Warning", {
          confirmButtonText: "Continue",
          cancelButtonText: "Cancel",
          type: "warning",
        }).then(async () => {
          this.saveMenuList();
        });
      }
    },
    async saveMenuList() {
      let params = {
        user_type: this.user_type,
        menu_list: this.data.map((e) => {
          if (e.groups == "") {
            delete e.groups;
          }
          if (e.filters == "") {
            delete e.filters;
          }
          if(e.customization == ''){
            delete e.customization
          }
          if (e.groups == "") {
            delete e.groups;
          }
          if (e.children && e.children.length) {
            e.children.map((el) => {
              if (el.groups == "") {
                delete el.groups;
              }
              if (el.filters == "") {
                delete el.filters;
              }
              if(el.customization == ''){
                delete el.customization
              }
              if (el.children && el.children.length) {
                el.children.map((ele) => {
                  if (ele.groups == "") {
                    delete ele.groups;
                  }
                  if (ele.filters == "") {
                    delete ele.filters;
                  }
                  if(ele.customization == ''){
                    delete ele.customization
                  }
                  return ele;
                });
              }
              return el;
            });
          }
          return e;
        }),
        type: "USER",
      };
      this.resetFormData();
      if (this.selectedMenuId) {
        params["_id"] = this.selectedMenuId;
        await this.$store.dispatch(
          "menuManagementV2/updateMenuManagement",
          params
        );
        if (this.getMenuManagementUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Menu Updated.",
          });
          this.fetchAllRoles();
          this.onChangeUserType(this.user_type);
        }
      } else {
        await this.$store.dispatch(
          "menuManagementV2/addMenuManagement",
          params
        );
        if (this.getMenuManagementAddStatus) {
          this.$notify.success({
            title: "Success",
            message: "Menu Added.",
          });
          this.onChangeUserType(this.user_type);
        }
      }
    },

    onMenuItemSubmit() {
      this.form.title = this.form.title.trim();
      let nameExists = false;
      if (!this.form.title) {
        this.$message.error("Please enter menu title");
        return;
      }
      let element = { ...this.form };
      let index = -1;
      if (element.type == "Child") {
        index = this.data.findIndex((x) => x.title == element.parent);
      } else if (element.type == "SubParent") {
        index = this.data.findIndex((x) => x.title == element.parent);
      } else if (this.editIndex > -1) {
        index = this.editIndex;
      } else {
        index = this.data.findIndex((x) => x.title == element.parent);
      }
      this.data.forEach((item, j) => {
        if(this.editIndex == -1){
        if (
          item.title &&
          j != index && 
          item.title.toLowerCase() == this.form.title.toLowerCase()
        ) {
          nameExists = true;
        } else if (item.children && item.children.length) {
          item.children.forEach((e, i) => {
            if(this.childEditIndex == -1){
            if( e.type == "Child" ){
              if(index == 0){
                  if (
                  e.title &&
                  i== index && this.form.type ==e.type &&
                  e.title.toLowerCase() == this.form.title.toLowerCase()
                  ) {
                    nameExists = true;
                  }
              }
              else if (
              e.title &&
              i!= index && this.form.type ==e.type &&
              e.title.toLowerCase() == this.form.title.toLowerCase()
            ) {
              nameExists = true;
            }
          }else if( e.type == "SubParent" ){
            if (
              e.title &&
              i!= index && this.form.type ==e.type &&
              e.title.toLowerCase() == this.form.title.toLowerCase()
            ) {
              nameExists = true;
            }
          else if (e.children && e.children.length && e.type == "SubParent") {
              e.children.forEach((el, k) => {
                if(this.subChildIndex == -1){
                    if (
                      el.title && el.type == "Child" &&
                      k != index  && this.form.type == el.type &&
                      el.title.toLowerCase() == this.form.title.toLowerCase()
                    ) {
                      nameExists = true;
                    }
                  }
              });
            }
          }
        }
          });
        }
      }
      });
      if (nameExists) {
        this.$notify.error({
          title: "Error",
          message: "Title name already exists",
        });
        return;
      }
      if (this.form.isGroup != "Groups") {
        this.form.groups = "6305c40df4a7749c62945728";
      }
      this.refresh = true;
      if (element.type == "Child") {
        let parentIndex = this.data.findIndex((x) => x.title == element.parent);
        element.children = [];
        if (parentIndex > -1) {
          let subParentIndex = this.data[parentIndex].children.findIndex(
            (x) => x.title == element.parent
          );
          if (this.childEditIndex > -1 && this.parentIndex == parentIndex) {
            this.data[parentIndex].children[this.childEditIndex] = element;
          } else if (
            this.childEditIndex > -1 &&
            this.parentIndex != parentIndex
          ) {
            this.data[this.parentIndex].children.splice(this.childEditIndex, 1);
            this.data[parentIndex].children.push(element);
          } else if (
            this.subChildIndex > -1 &&
            this.subParentIndex != subParentIndex
          ) {
            this.data[this.parentIndex].children[
              this.subParentIndex
            ].children.splice(this.subChildIndex, 1);
            this.data[parentIndex].children.push(element);
          } else {
            this.data[parentIndex].children.push(element);
          }
        } else {
          this.data.forEach((parent, i) => {
            let subParentIndex = parent.children.findIndex(
              (x) => x.title == element.parent
            );
            element.children = [];
            if (subParentIndex > -1) {
              if (
                this.subChildIndex > -1 &&
                this.subParentIndex == subParentIndex
              ) {
                // if (this.parentIndex != parentIndex) {
                //   this.data[this.parentIndex].children[
                //     this.subParentIndex
                //   ].children.splice(this.subChildIndex, 1);
                //   this.data[i].children[subParentIndex].children.push(element);
                // } else {
                  this.data[i].children[subParentIndex].children[
                    this.subChildIndex
                  ] = element;
                // }
              } else if (
                this.childEditIndex > -1 &&
                this.parentIndex != parentIndex
              ) {
                this.data[this.parentIndex].children.splice(
                  this.childEditIndex,
                  1
                );
                if (this.childEditIndex < subParentIndex) {
                  this.data[i].children[this.subParentIndex].children.push(
                    element
                  );
                } else {
                  this.data[i].children[subParentIndex].children.push(element);
                }
              } else if (
                this.subChildIndex > -1 &&
                this.subParentIndex != subParentIndex
              ) {
                this.data[this.parentIndex].children[
                  this.subParentIndex
                ].children.splice(this.subChildIndex, 1);
                this.data[i].children[subParentIndex].children.push(element);
              } else {
                this.data[i].children[subParentIndex].children.push(element);
              }
            }
          });
        }
        if (this.editIndex > -1) {
          this.data.splice(this.editIndex, 1);
        }
      } else if (element.type == "SubParent") {
        let parentIndex = this.data.findIndex((x) => x.title == element.parent);
        let subParentIndex = this.data[parentIndex].children.findIndex(
          (x) => x.title == element.parent
        );
        if (parentIndex > -1) {
          if (this.childEditIndex > -1 && this.parentIndex == parentIndex) {
            this.data[parentIndex].children[this.childEditIndex] = element;
          } else if (
            this.childEditIndex > -1 &&
            this.parentIndex != parentIndex
          ) {
            this.data[this.parentIndex].children.splice(this.childEditIndex, 1);
            this.data[parentIndex].children.push(element);
          } else if (
            this.subChildIndex > -1 &&
            this.subParentIndex != subParentIndex
          ) {
            this.data[this.parentIndex].children[
              this.subParentIndex
            ].children.splice(this.subChildIndex, 1);
            this.data[parentIndex].children.push(element);
          } else {
            this.data[parentIndex].children.push(element);
          }
        }
        if (this.editIndex > -1) {
          this.data.splice(this.editIndex, 1);
        }
      } else if (element.type == "Parent") {
        if (this.editIndex > -1) {
          this.data[this.editIndex] = element;
        } else {
          if (this.childEditIndex > -1 && element.parent) {
            let parentIndex = this.data.findIndex(
              (x) => x.title == element.parent
            );
            this.data[parentIndex].children.splice(this.childEditIndex, 1);
            element.parent = "";
          } else if (this.subChildIndex > -1 && element.parent) {
            let subParentIndex = this.data[this.parentIndex].children.findIndex(
              (x) => x.title == element.parent
            );
            this.data[this.parentIndex].children[
              subParentIndex
            ].children.splice(this.subChildIndex, 1);
            element.parent = "";
          }
          this.data.push(element);
        }
      } else {
        if (this.editIndex > -1) {
          this.data[this.editIndex] = element;
        } else {
          if (this.childEditIndex > -1 && element.parent) {
            let parentIndex = this.data.findIndex(
              (x) => x.title == element.parent
            );
            this.data[parentIndex].children.splice(this.childEditIndex, 1);
            element.parent = "";
          } else if (this.subChildIndex > -1 && element.parent) {
            let subParentIndex = this.data[this.parentIndex].children.findIndex(
              (x) => x.title == element.parent
            );
            this.data[this.parentIndex].children[
              subParentIndex
            ].children.splice(this.subChildIndex, 1);
            element.parent = "";
          }

          this.data.push(element);          
        }
      }
      // if (this.editIndex > -1) {
      //   if (element.type == "Parent") {
      //     // we have to update children names
      //     element.children = element.children.map((e) => {
      //       e.parent = element.title;
      //       return e;
      //     });
      //     this.data[this.editIndex] = element;
      //   } else if(element.type == "Child") {
      //     let parentIndex = this.data.findIndex(
      //       (x) => x.title == element.parent
      //     );
      //     if (parentIndex > -1) {
      //       this.data[parentIndex].children.push(element);
      //     }
      //     this.data.splice(this.editIndex, 1);
      //   }
      // }else if (this.parentIndex > -1 && this.childEditIndex > -1) {
      //   this.data[this.parentIndex].children[this.childEditIndex] = element;
      // } else {
      //   if (element.type == "Child" && element.parent) {
      //     let parentIndex = this.data.findIndex(
      //       (x) => x.title == element.parent
      //     );
      //     if (parentIndex > -1) {
      //       this.data[parentIndex].children.push(element);
      //     }
      //   } else {
      //     this.data.push(element);
      //   }
      // }
      // we have to clear form
      this.editIndex = -1;
      this.childEditIndex = -1;
      this.subChildIndex = -1;
      this.subParentIndex = -1;
      this.form = {
        title: "",
        feature: "",
        category: "",
        type: "",
        parent: "",
        icon: "dashboard.svg",
        children: [],
        isGroup: "",
        groups: "",
        bulkUpdate : true,
        redirectMenuItem: '',
      };
      setTimeout(() => {
        this.refresh = false;
      });
    },

    onMenuItemCancel() {
      this.editIndex = -1;
      this.parentIndex = -1;
      this.childEditIndex = -1;
      this.subChildIndex = -1;
      this.subParentIndex = -1;
      this.form = {
        title: "",
        feature: "",
        category: "",
        type: "",
        parent: "",
        icon: "dashboard.svg",
        children: [],
        bulkUpdate : true,
        redirectMenuItem: '',
      };
    },
    async editMenuItem(data, node = {}) {
      try {
        if (data && data.feature == "ENTITIES") {
          await this.getFeatures(
            data.entity_id && data.entity_id._id
              ? data.entity_id._id
              : data.entity_id
          );
        }
        if (node.level == 1) {
          this.editIndex = this.data.findIndex((x) => x.title == data.title);
          let type =
            this.data[this.editIndex]?.children &&
            this.data[this.editIndex].children.length
              ? "Parent"
              : this.data[this.editIndex].type == "Child" ||
                this.data[this.editIndex].type == "SubParent"
              ? "Individual"
              : "Individual";
          this.form = { ...this.data[this.editIndex], ...{ type: type } };
        } else if (node.level == 2) {
          this.parentIndex = this.data.findIndex(
            (x) => x.title == node.parent.data.title
          );
          this.childEditIndex = this.data[this.parentIndex].children.findIndex(
            (y) => y.title == data.title
          );
          this.subParentIndex = this.data[this.parentIndex].children.findIndex(
            (y) => y.title == data.title
          );
          if (node.parent.data.children[this.childEditIndex].type == "Child") {
            this.form = {
              parent: node.parent.data.title,
              ...this.data[this.parentIndex].children[this.childEditIndex],
              ...{ type: "Child", parent: this.data[this.parentIndex].title },
            };
          } else if (
            node.parent.data.children[this.subParentIndex].type == "SubParent"
          ) {
            this.form = {
              parent: node.parent.data.title,
              ...this.data[this.parentIndex].children[this.subParentIndex],
              ...{
                type: "SubParent",
                parent: this.data[this.parentIndex].title,
              },
            };
          }
        } else if (node.level == 3) {
          this.data.forEach((e) => {
            if (e && e.children && e.children.length) {
              e.children.forEach((f) => {
                if (f.title === node.parent.data.title) {
                  this.subParentIndex = e.children.indexOf(f);
                  this.parentIndex = this.data.indexOf(e);
                  this.subChildIndex = node.parent.data.children.findIndex(
                    (e) => e.title === node.data.title
                  );
                }
              });
            }
          });
          this.form = {
            parent: node.parent.data.title,
            ...this.data[this.parentIndex].children[this.subParentIndex]
              .children[this.subChildIndex],
            ...{
              type: "Child",
              parent:
                this.data[this.parentIndex].children[this.subParentIndex].title,
            },
          };
          if(this.form.entity_id?._id) {
            this.form.entity_id = this.form.entity_id._id
          }
        }
        if (this.form.type !== "Parent") {
          this.fetchCategories(this.form.feature);
          this.fetchParents();
        }
      } catch (err) {
        console.log("editMenuItem",err);
        this.errorConfirm();
      }
    },
    async deleteMenuItem(data, node = {}) {
      try {
        this.refresh = true;
        if (node.level == 1) {
          let index;
          index = this.data.findIndex(
            (x) => JSON.stringify(x) === JSON.stringify(data)
          );
          if (index > -1) {
            this.data.splice(index, 1);
          }
        }

        if (node.level == 2) {
          let parentIndex = this.data.findIndex(
            (x) => x.title === node.parent.data.title
          );
          let childIndex = this.data[parentIndex].children.findIndex(
            (item) => item.title === data.title
          );
          this.data[parentIndex].children.splice(childIndex, 1);
        }
        if (node.level == 3) {
          this.data.forEach((parent, i) => {
            if (
              parent &&
              parent.children &&
              parent.children.length &&
              (this.ParentIndex == undefined || this.ParentIndex == -1)
            ) {
              this.ParentIndex = parent.children.findIndex(
                (x) => x.title == node.parent.data.title
              );
              if (this.ParentIndex !== -1) {
                this.childEditIndex = this.data[i].children[
                  this.ParentIndex
                ].children.findIndex((item) => item.title === data.title);
                this.data[i].children[this.ParentIndex].children.splice(
                  this.childEditIndex,
                  1
                );
              }
            }
          });
          this.ParentIndex = -1;
        }
        this.refresh = false;
      } catch (err) {
        console.log("deleteMenuItem",err);
        this.refresh = false;
        this.errorConfirm();
      }
    },
    errorConfirm() {
      this.$confirm(
        "Menu is not configured properly. Click on continue to delete menu and add new one.",
        "Warning",
        {
          confirmButtonText: "Continue",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.deleteMenu();
      });
    },
    uploadFile(file) {
      this.form.selectedFile = file.raw;
      this.form.selectedFileName = file.raw.name;
    },
    setIconData(name) {
      this.form.icon = name;
      this.selectedIcon = name;
      this.iconPickerdialogVisible = false;
    },
    closeIconPopUp(){
      this.iconPickerdialogVisible = false;
    },
    // renderContent(h, { data, node }) {
    //   return h("span", [h("span", node.id), h("span", data.title)]);
    // },
  },
};
</script>

<style lang="scss" scoped>
.icons-menu ::v-deep .dialog-content{
    scrollbar-width: none;
}
.sidemenu {
  height: 420px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
}

.sidemenu::-webkit-scrollbar {
  width: 0.6em;
}

.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}

//   .sidemenu::-webkit-scrollbar-button:single-button {
//       background-color: #bbbbbb;
//       display: block;
//       border-style: solid;
//     //  height: 13px;
//     //  width: 16px;
//     }
// .sidemenu::-webkit-scrollbar-button:single-button:vertical:decrement {
//   border-width: 0 8px 8px 8px;
//   border-color: transparent transparent #555555 transparent;
// }

// .sidemenu::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
//   border-color: transparent transparent #777777 transparent;
// }
// /* Down */
// .sidemenu::-webkit-scrollbar-button:single-button:vertical:increment {
//    border-width: 8px 8px 0 8px;
//   border-color: #555555 transparent transparent transparent;
// }

// .sidemenu::-webkit-scrollbar-button:single-button:vertical :increment:hover {
//   border-color: #777777 transparent transparent transparent;
// }
.user-menu-container {
  background: #f5f7ff;
  border: 1px solid rgba(168, 171, 255, 0.3);
  border-radius: 20px;
  padding: 2em;
  position: relative;

  .menuPreviewButton {
    position: absolute;
    top: -1.5em;
    right: 15em;
    border-radius: 5px;
  }

  .menuSubmitButton {
    position: absolute;
    top: -1.5em;
    right: 1em;
    right: 6.5em;
    border-radius: 5px;
  }
  .menuDeleteButton {
    position: absolute;
    top: -1.5em;
    right: 1em;
    border-radius: 5px;
  }


  .el-tree {
    background-color: transparent;

    .el-tree-node__content {
      height: 6em;

      &:hover {
        background-color: #f7f9fd;
      }
    }
  }

  .each-item {
    width: 25em;
    overflow: visible;
    margin-bottom: 1em;
    min-height: 5em;
    position: relative;
    z-index: 20;

    .el-card__body {
      padding-top: 0.75em;
    }

    &:last-child {
      margin-bottom: 0em;
    }

    & + .sub-item-1 {
      position: relative;
      margin-left: 3.5em;
      z-index: 15;

      &:after {
        content: "";
        position: absolute;
        top: 2em;
        left: -2em;
        width: 2em;
        border-top: 2px dashed #285fd3;
        height: 1px;
      }

      &:before {
        content: "";
        position: absolute;
        top: -3.75em;
        left: -2em;
        width: 2px;
        border-left: 2px dashed #285fd3;
        height: calc(100% + 1em);
      }
    }

    & + .sub-item-2 {
      position: relative;
      margin-left: 5.5em;
      z-index: 15;

      &:after {
        content: "";
        position: absolute;
        top: 2em;
        left: -4em;
        width: 4em;
        border-top: 2px dashed #285fd3;
        height: 1px;
      }

      &:before {
        content: "";
        position: absolute;
        top: -3.75em;
        left: -4em;
        width: 2px;
        border-left: 2px dashed #285fd3;
        height: calc(100% + 1em);
      }
    }
  }
}

.template-icon {
  width: 30px;
  height: 20px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  text-align: center;
}

.border-radius {
  border-radius: 4px;
}
</style>
<style scoped>

.el-button--primary {
  color: var(--primary-color) !important;
  background-color: var(--primary-contrast-color) !important;
  border-color: var(--primary-color) !important;
}
.el-button--primary:hover {
  color: var(--primary-contrast-color) !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.el-button--success{
  color: #FFF;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.el-button--success:hover{
  color: #FFF;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.el-button--success:disabled{
  color: #FFF;
  background-color: var(--lighter-background);
  border-color: var(--primary-color);
}
.float-right {
  float: right !important;
  position: relative;
  bottom: 20px;
}
.el-button.is-plain:hover{
  color:var(--primary-color);
  background-color:var(--primary-contrast-color);
  border-color:var(--primary-color)
}
</style>
